<template>
  <v-container fluid>
    <v-card elevation="0">
      <v-card-title class="mb-4">{{ media.name }}</v-card-title>
      <v-card-subtitle align="left" class="pt-0 pb-0">담당자</v-card-subtitle>
      <v-card-text class="pt-0 pb-0">
        <v-row align="center">
          <v-col>
            <v-text-field
              label="이름"
              v-model="media.contact_name"
              readonly
              filled
              background-color="#EFEFF7"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="연락처"
              v-model="media.contact_phone"
              readonly
              filled
              background-color="#EFEFF7"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Email"
              v-model="media.contact_email"
              readonly
              filled
              background-color="#EFEFF7"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="홈페이지"
              v-model="media.contact_url"
              readonly
              filled
              background-color="#EFEFF7"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-subtitle align="left" class="pt-0 pb-0">
        서비스 타임라인
      </v-card-subtitle>
      <v-card-text class="pt-0 pb-0">
        <v-row align="center">
          <v-col>
            <v-text-field
              label="스크립트 전달"
              v-model="media.send_date"
              readonly
              filled
              background-color="#EFEFF7"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="스크립트 설치"
              v-model="media.deploy_date"
              readonly
              filled
              background-color="#EFEFF7"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="서비스 실행"
              v-model="media.active_date"
              readonly
              filled
              background-color="#EFEFF7"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <!-- <v-card-subtitle align="left" class="pt-0 pb-0"> 정산 </v-card-subtitle>
      <v-card-text class="pt-0 pb-0">
        <v-row align="center">
          <v-col>
            <v-text-field
              label="정산 응답 횟수"
              v-model="answerCount"
              readonly
              filled
              background-color="#EFEFF7"
              prefix="회"
              reverse
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="누적 언론사 정산액"
              v-model="total"
              readonly
              filled
              background-color="#EFEFF7"
              prefix="원"
              reverse
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="정산 조건 응답 1회당"
              prefix="원"
              v-model="media.calculate_price"
              readonly
              filled
              background-color="#EFEFF7"
              reverse
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text> -->
      <v-card-subtitle align="left" class="pt-0 pb-0">
        카테고리
      </v-card-subtitle>
      <v-card-text class="pt-0 pb-0">
        <v-row no-gutters>
          <v-checkbox
            v-for="cate in category_items"
            v-model="media_category"
            :key="cate.id"
            :value="cate.id"
            :label="cate.name"
            color="#22bb33"
            class="mr-2"
            @change="categoryChange()"
          ></v-checkbox>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- <v-card elevation="0" class="mt-6">
      <v-card-title class="mb-4"> 콘텐츠 현황 </v-card-title>
      <v-card-text>
        <v-sheet color="white">
          <v-sparkline
            :value="value"
            color="blue darken-1"
            height="60"
            padding="24"
            stroke-linecap="round"
            smooth
            auto-draw
            label-size="3"
            line-width="1"
          >
            <template v-slot:label="item">
              {{ item.value }}
            </template>
          </v-sparkline>
        </v-sheet>
      </v-card-text>
    </v-card>
    <v-row v-if="loading" class="justify-center" :loading="loading">
      <Loading :loading="loading"></Loading>
    </v-row>
    <v-row class="mt-6 flexDiv">
      <v-col v-for="(item, i) in items" :key="i" cols="12" sm="6" md="4" lg="2">
        <v-card
          style="margin: 0; padding: 0; border: 3px solid #43a047"
          v-if="item.status === 1"
          height="100"
        >
          <v-card-title>
            {{ item.name }}
          </v-card-title>
        </v-card>
        <v-card
          style="margin: 0; padding: 0; border: 3px solid #e53935"
          v-else
          height="100"
        >
          <v-card-title>
            {{ item.name }}
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-pagination
      v-if="loading === false"
      class="mt-10 mb-5"
      circle
      v-model="page"
      :length="pageCount"
      prev-icon="mdi-menu-left"
      next-icon="mdi-menu-right"
      color="primary"
      :total-visible="10"
      @input="getData()"
    ></v-pagination> -->
  </v-container>
</template>
<script>
// import Loading from "@/components/common/Loading";

export default {
  name: "MediaDetail",
  // components: {
  //   Loading,
  // },
  props: {
    media_id: Number,
  },
  data() {
    return {
      items: [],
      category_items: [],
      media: {},
      media_category: [],
      value: [423, 446, 675, 510, 590, 610, 760],
      loading: false,
      page: 1,
      per_page: 30,
      pageCount: 1,
      answerCount: null,
      totalPrice: null,
      total: null,
      answer: null,
    };
  },
  mounted() {
    this.getData();
    this.getCategory();
  },
  methods: {
    getData() {
      this.loading = true;
      let items = [];
      let media_id = null;
      if (
        typeof this.$route.params.id === "undefined" ||
        parseInt(localStorage.getItem("media_id")) !== 999
      ) {
        media_id = this.media_id;
      } else {
        media_id = this.$route.params.id;
      }
      this.axios
        .get("api/v1/admin/medias/" + media_id)
        .then((res) => {
          if (res.data.data.campaigns.length > 0) {
            res.data.data.campaigns.map((item, index) => {
              items.push({
                no: index + 1,
                id: item.id,
                name: item.name,
                status: item.status,
              });
            });
          }
          this.media = res.data.data;
          this.media_category = res.data.data.categories.map((item) => item.id);
          this.answerCount = res.data.data.survey_total_count;
          this.totalPrice =
            res.data.data.survey_total_count * res.data.data.calculate_price;
          this.total = this.totalPrice.toLocaleString();
          this.items = items.reverse();
          this.loading = false;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getCategory() {
      this.loading = true;
      let items = [];
      this.axios
        .get("api/v1/admin/categories", {})
        .then((res) => {
          if (res.data.data.categories.length > 0) {
            res.data.data.categories.map((item, index) => {
              items.push({
                no: index + 1,
                id: item.id,
                name: item.name,
              });
            });
          }
          this.category_items = items;
          this.loading = false;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    categoryChange() {
      const data = {
        name: this.media.name,
        contact_name: this.media.contact_name,
        contact_phone: this.media.contact_phone,
        contact_email: this.media.contact_email,
        contact_url: this.media.contact_url,
        category_ids: this.media_category,
        send_date: this.media.send_date,
        deploy_date: this.media.deploy_date,
        active_date: this.media.active_date,
        calculate_unit: this.media.calculate_unit,
        calculate_price: this.media.calculate_price,
      };
      this.axios
        .put("api/v1/admin/medias/" + this.media.id, data)
        .then(() => {
          this.getData();
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style scoped>
.flexDiv > col {
  align-items: center;
}
</style>
